a {
  color: white !important;
  text-decoration: none !important;
}
.external-link {
  color: rgb(255, 15, 15) !important;
}
body {
  font-family: system-ui -apple-system "Segoe UI" Roboto "Helvetica Neue" "Noto Sans"
    "Liberation Sans" Arial sans-serif "Apple Color Emoji" "Segoe UI Emoji" "Segoe UI Symbol"
    "Noto Color Emoji" !important;
}
/* Navbar-page starting */

.navbar-brand img {
  position: relative;
  left: 55%;
}
.book-now-btn-navbar {
  color: #cd2034;

  position: relative;
  right: 8%;
}
.book-now-btn-navbar p {
  color: #cd2034;
  margin: 0% !important;
  font-weight: 600;
}

.navbar {
  background-color: white !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.nav-link a {
  color: black !important;
}

.nav-link {
  color: black !important;
}
.me-auto {
  width: 100%;
  display: flex;
  justify-content: center;
}
.me-auto .nav-link {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.me-auto-2 {
  position: relative;
  right: 5%;
}
.me-auto-3 {
  display: none !important;
}
/* Navbar-page ending */

/* Crousel-page starting */
.carousel-caption {
  position: absolute;
  /* right: 15%; */

  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: start !important;
  line-height: 7px;
}

.carousel-control-prev-icon {
  display: inline-block;
  width: 1rem !important;
  height: 1rem !important;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 1rem !important;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-caption h3 {
  font-size: 40px;
}

.crousel-left-icon-p {
  border: 1px solid white;
  width: fit-content;
  padding: 6px;
  border-radius: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.crousel-left-icon {
  position: relative;
  left: 4%;
  font-size: 27px;
}

.banner-heading-2 {
  margin-top: -12px;
}

/* Crousel-page end` */

.footer-phone-btn button {
  background: none !important;
  border: 0px !important;
}

/* Owel-crousel starting */

.item img {
  width: 170px !important;
}

.owl-theme-1 {
  border-right: 1px solid black;
}

.owl-theme-p {
  margin-bottom: 0px;
  font-weight: 700;
  color: red !important;
  text-align: center;
}
.owl-theme-p-1 {
  margin-bottom: 0px;
  font-weight: 700;
  color: red !important;
  text-align: center;
}
.item {
  border-right: 1px solid black;
  display: grid;
  justify-content: center;
}

.owl-item {
  padding: 10px;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: none !important;
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

/* Owel-crousel ending */

/* Home-page start */
.home-cards {
  padding: 30px;
}

/* Home-page end */

/* Auto-rent-advantage-section starting */

.circle-icon {
  background: #cd2034;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  vertical-align: middle;
  padding: 5px;
  font-size: 20px;
}

.auto-rent-heading-1 {
  text-align: center;
  font-weight: 900;
  font-size: 55px;
  margin-bottom: -6px;
}
.auto-rent-heading-2 {
  text-align: center;
  font-weight: 300;
  margin-left: 0%;
  font-size: 55px;
}
.auto-rent-heading-3 {
  text-align: center;
  background-color: #cd2034;
  padding: 10px 10px;
  width: 43%;
  color: white;
}
.heading-div {
  display: flex;
  justify-content: center;
}
.compant-profile div hr {
  width: 10%;
  color: #cd2034;
  border: 0;
  border-top: 2px solid;
  opacity: 0.9 !important;
  position: absolute;
}
.auto-rent-row {
  display: flex;
  justify-content: center;
}

.c-profile {
  font-weight: 700;
  margin-left: 38%;
}

.compant-profile {
  display: flex;
  justify-content: center;
}
.p-text {
  text-align: justify;
  margin-bottom: 5%;
  margin-top: 5%;
  width: 90%;
}
.card-col .card {
  min-height: 280px !important;
  border: 1px solid black !important;
}

/* Auto-rent-advantage-section endting */

/* Whatsapp-icon staring */
.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
.whatsapp-div {
  width: 60px;
  height: 60px;
  background-color: #00509f;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  z-index: 1;
  justify-content: center;
  color: white !important;
}
.whatsapp-div div {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Whatsapp-icon ending */

/* New design start triangle-right  left  */
.triangle-right {
  width: 0;
  height: 0;
  border-top: 80px solid transparent;
  border-left: 100px solid #181717;
  border-bottom: 80px solid transparent;
  opacity: 0.9;
}

.triangle-right-1 {
  width: 0;
  height: 0;
  border-top: 80px solid transparent;
  border-left: 100px solid #317ce0;
  border-bottom: 80px solid transparent;
  opacity: 0.9;
}

.triangle-left {
  width: 0;
  height: 0;
  border-top: 80px solid transparent !important;
  border-right: 100px solid #e54056;
  border-bottom: 80px solid transparent !important;
  opacity: 0.9;
}

.triangle-left-1 {
  width: 0;
  height: 0;
  border-top: 80px solid transparent;
  border-right: 100px solid #dbddde;
  border-bottom: 80px solid transparent;
  opacity: 0.9;
}

/* New design ending */

/* BIKE Details starting */

.b-btn {
  border: 2px solid white;
  border-radius: 20px;
  margin-right: 30%;
  padding: 6px;
  width: 150px;
  text-align: center;
  margin: 0px !important;
  font-weight: 500;
}
.b-btn p {
  margin-bottom: -4%;
  margin-left: 4.8%;
}
.bike-info {
  background: #d4915a;

  opacity: 0.9;
}
.bike-info-2 {
  background: #f85e76;
  color: #fff;
  height: 350px;
  opacity: 0.9;
  padding-left: 5%;
  padding-right: 8%;
  padding-top: 5%;
  position: relative;
  right: 25%;
}

/* Bike Details ending */

/* Bike Details startin */
hr {
  width: 10%;
  color: #cd2034;
  border: 0;
  border-top: 2px solid;
  opacity: 0.9 !important;
  position: absolute;
}

/* Bike Details ending */

/* About-page starting */

.row-about {
  display: flex;
  justify-content: center;
}
.row-about .col-lg-3 .card {
  min-height: 300px !important;
  display: flex;
  align-items: center;
  background-color: #91b2c1;
  color: white;
}
.row-about .col-lg-3 .card span svg {
  font-size: 50px;
}
.value-card p {
  margin: 0px !important;
}
.about-heading {
  text-align: center;
  color: rgb(205, 32, 52);
}
.About-Autorent {
  text-align: center;
  color: #cd2034;
}
.about-row-2 {
  display: flex;
  justify-content: center;
}
.ab-btn {
  --bs-btn-bg: none !important;
  border: 1px solid white !important;
}

/* About-page ending */

/* Media Image-gallery starting */
.Feaild {
  margin-top: 3% !important;
  margin-bottom: 1%;
  display: flex;
  justify-content: center;
}
.Feaild .col-lg-2 {
  padding: 0px;
  width: 150px;
  display: flex;
  justify-content: center;
}
.images-row {
  display: flex;
  justify-content: center;
}
.images-row .col-lg-3 {
  padding: 0px;
  width: 270px;
  display: flex;
  justify-content: center;
  padding-top: 25px;
}

.hover-img {
  color: #fff;
  display: inline-block;

  overflow: hidden;
  position: relative;
}

.hover-img * {
  box-sizing: border-box;
  transition: all 0.45s ease;
}

.hover-img:before,
.hover-img:after {
  background-color: rgba(0, 0, 0, 0.6);

  position: absolute;
  top: 36%;

  bottom: 0;
  left: 39%;
  right: 0;
  content: "";
  transition: all 0.3s ease;
  z-index: 1;
  opacity: 0;
  transform: scaleY(2);
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.hover-img img {
  vertical-align: top;
  backface-visibility: hidden;
}

.hover-img figcaption {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.1em;
  opacity: 0;
  z-index: 2;
  transition-delay: 0.1s;
  font-size: 24px;
  font-family: sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.hover-img:hover:before,
.hover-img:hover:after {
  transform: scale(1);
  opacity: 1;
}

.hover-img:hover > img {
  opacity: none;
}

.hover-img:hover figcaption {
  opacity: 1;
}

/* Media Image-gallery Ending */

/* Login-page Starting */

.container-loginpage {
  height: 100vh;
  display: flex;
  align-items: center;
}

.container-loginpage .card {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.container-loginpage .card-header {
  background-color: #f8f9fa;
  border-bottom: none;
  text-align: center;
  padding: 20px;
}

.container-loginpage .card-body {
  padding: 20px;
}

.container-loginpage .form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
}

.container-loginpage .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.container-loginpage .btn-primary:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}

/* Login-page ending */

/* Home-gallary starting */
.hr-gallary {
  width: 8%;
  color: #cd2034;
  border: 0;
  border-top: 2px solid;
  opacity: 0.9 !important;
  position: absolute;
  left: 43.4%;
}
.g-heading {
  font-weight: 900;
  position: relative;
}
.c-profile-1 {
  text-align: center;
  margin-left: 9.5%;
}

/* Home-gallary ending */

/* Contact-page starting   */
.contact-row {
  display: flex;
  justify-content: center;
}
.contact-row h4 {
  color: #292c33;
}
.contact-row .col-lg-6 form {
  background-color: #292c33;
  padding: 60px;
  min-height: 300px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.contact-row .contact-us .row .col-lg-12 .card {
  background-color: #cd2034;
  min-height: 180px;
  display: grid;
  justify-content: center;
  align-items: center;
  min-width: 250px;
}
.contact-us {
  display: grid;
  text-align: center;
}
.contact-us-2 {
  display: grid;
  text-align: center;
}
.card-position {
  position: relative;
  right: 25%;
  top: 15%;
}
.card-position-card {
}

.toast-body {
  background: none !important;
  color: white !important;
}

/* Contact-page ending */

/* footer-image page-startin */

.footer-image {
  background-color: #cd2034;
  width: 300px;
}
.footer-img-col .col-lg-12 {
  display: flex;
  justify-content: end;
  padding: 0px !important;
  height: 100px;
}
.footer-img-col {
  display: flex;
  justify-content: center;
}
.b-btn-1 {
  background-color: white !important;
  border: 1px solid white;
  border-radius: 20px;
  margin-right: 30%;
  padding: 6px !important;
  width: 150px;
  text-align: center;
  margin: 0px !important;
  width: 100%;
  color: black !important;
  font-size: 12px;
}
.quick-links p {
  color: white;
  margin-bottom: 0px !important;
  font-size: 14px;
}
.quick-links h6 {
  color: white;
}
.footer-img-col {
  display: flex;
  justify-content: center;
}
.footer-div-1 {
  color: white;
  position: relative;
  left: 22%;
}
.quick-links {
  display: grid;
  justify-content: center;
}
.footer-bottom {
  padding-bottom: 50px;
}

.footer-btn {
  border: none !important;
  border-radius: 999em 20px 20px 999em;
  padding: 12px;
  width: 220px;
  font-size: 10px;
  background-color: white;
  color: black;
  box-shadow: none !important;
}

.footer-phone-btn {
  border-radius: 20px 999em 999em 20px;
  padding: 13px;
  background-color: rgb(191, 51, 67);
  margin-top: 5px !important;
  width: 50px !important;
  font-size: 10px !important;
  position: relative;
  right: 2%;
}
/* footer-page ending */

/* Brands-deatis-inside-page starting */

.brands-inside-row {
  display: flex;
  justify-content: center;
}
td {
  font-size: 14px;
}
.row-info-details {
  display: flex;
  justify-content: center;
}
.details-line {
  width: 10%;
  color: #cd2034;
  border: 0;
  border-top: 2px solid;
  opacity: 0.9 !important;
  position: relative !important;
  width: 80% !important;
  margin-top: 5% !important;
}

.owl-theme-p-1 {
  text-align: start !important;
  font-size: 18px;
}

/* Brands-details-inside-page ending */

/* Brand img */
.img-center {
  display: flex;
  justify-content: center;
}
h4 {
  font-size: 55px !important;
  font-weight: 300 !important;
}

/* Brand-page starting */

.brand-carousel {
  position: relative;
  margin-top: -36% !important;
}
.brand-carousel .carousel-caption {
  top: -4%;
  left: 12% !important;
}

.col-bike-image {
  display: flex;
  justify-content: center;
}
.row-bike-image {
  display: flex;
  justify-content: center;
}

.brand-bike-row-img {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 40%;
}

/* Brand-page ending */

@media screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .bike-explore {
    display: none !important;
  }

  .bike-brand-img {
    position: relative !important;
    right: -28% !important;
  }

  .triangle-left-5 {
    width: auto !important;
    height: auto !important;
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%) !important;
  }

  .triangle-right-5 {
    width: auto !important;
    height: auto !important; /* Adjust this value to change the height of the shape */
    clip-path: polygon(100% 0%, 0% 50%, 100% 100%) !important;
  }

  .card-position {
    position: relative;
    right: 0% !important;
    top: 15%;
  }

  .heaing-one {
    padding: 3px !important;
  }

  .gallray-long-img {
    width: 100%;
  }

  /* bike info start */
  .b-btn {
    border: 2px solid white;
    border-radius: 20px;
    margin-right: 30%;
    padding: 6px;
    width: 30% !important;
    text-align: center;
    margin: 0px !important;
    font-weight: 500;
  }
  .b-btn p {
    margin-left: 0%;
    font-size: 10px !important;
  }
  .bike-info {
    background: #d4915a;
    color: #fff;
    opacity: 0.9;
    padding-left: 5%;
    padding-right: 8%;
    padding-top: 5%;
    position: relative;
    left: 0%;
    width: 100%;
    height: 80% !important;
  }
  .bike-info h1 {
    margin: 0% !important;
  }
  .bike-info-2 h1 {
    margin: 0% !important;
  }
  .bike-info-2 {
    background: #f85e76;
    color: #fff;
    height: 350px;
    opacity: 0.9;
    padding-left: 5%;
    padding-right: 8%;
    padding-top: 5%;
    position: relative;
    right: 0%;
  }

  .bike-img img {
    width: 100%;
    /* background-color: #EFEFEF; */
  }

  /* Bike-info end */

  /* BIKE-FORM STARTING */

  .owl-carousel .owl-nav button.owl-prev {
    position: absolute !important;
    top: 40% !important;
    left: -15px;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #000;
    font-size: 50px !important;
  }
  /* BIKE-FORM ENDING */

  /* navbar- start */
  .navbar {
    padding: 0px !important;
  }
  .me-auto-2 {
    display: none !important;
    width: 100%;
  }
  .me-auto-3 {
    display: block !important;
    width: 100%;
    background-color: #cd2034;
  }
  .me-auto-3 .col-lg-7 span a {
    color: black;
  }
  .book-now-btn-navbar {
    position: relative;
  }
  .icon-xs-col .nav-link {
    padding: 10px 10px 10px 10px;
    color: white !important;
  }
  .navbar-brand img {
    position: relative;
    left: 5% !important;
  }
  .navbar-toggler {
    position: relative;
    right: 5%;
  }

  /* navbar end */

  .carousel-caption {
    display: none;
  }

  /* Owel-crousel starting */

  .item img {
    width: 170px !important;
  }

  .owl-theme-1 {
    border: none !important;
  }

  .owl-theme-p {
    margin-bottom: 0px;
    font-weight: 700;
    color: red !important;
    text-align: center;
  }
  .owl-theme-p-1 {
    margin-bottom: 0px;
    font-weight: 700;
    color: red !important;
    text-align: center;
  }
  .item {
    border-right: none;
    display: grid;
    justify-content: center;
  }
  /* Owel-crousel ending */

  /* Auto-rent-advantage-section starting */

  .circle-icon {
    background: #cd2034;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    padding: -10px;
    font-size: 10px;
    margin-top: 2%;
  }

  .auto-rent-heading-1 {
    text-align: center;
    font-weight: 900;
    font-size: 22px;
    margin-bottom: -6px;
  }
  .auto-rent-heading-2 {
    text-align: center;
    font-weight: 300;
    margin-left: -4%;
    font-size: 22px;
  }
  .auto-rent-heading-3 {
    text-align: center;
    background-color: #cd2034;
    padding: 6px 6px;
    width: 70% !important;
    color: white;
    font-size: 8px;
  }
  .auto-rent-font {
    text-align: center;
  }
  .heading-div {
    display: flex;
    justify-content: center;
  }
  .compant-profile div hr {
    width: 20%;
    color: #cd2034;
    border: 0;
    border-top: 2px solid;
    opacity: 0.9 !important;
    position: absolute;
    left: 15%;
  }
  .auto-rent-row {
    display: flex;
    justify-content: center;
  }

  .c-profile {
    font-weight: 700;
    margin-left: 50%;
    font-size: 14px;
  }
  .compant-profile {
    display: flex;
    justify-content: center;
  }
  .p-text {
    text-align: justify;
    margin-bottom: 5%;
    margin-top: 5%;
    width: 100%;
    padding: 0px 30px 0px 30px;
    font-size: 14px;
  }
  .arrow-p {
    font-size: 14px;
    padding: 0px 30px;
  }
  .icon-content h5 {
    font-size: 16px;
  }
  .icon-content p {
    font-size: 14px;
  }
  .icon-content {
    padding: 0px 0px !important;
  }
  .bike-info {
    height: fit-content;
    padding: 5px;
  }
  .bike-info-2 {
    height: fit-content !important;
    padding: 5px;
  }
  .bike-info h1 {
    font-size: 12px;
  }
  .bike-info p {
    font-size: 10px;
    margin-bottom: 0px !important;
  }

  .bike-info-2 h1 {
    font-size: 12px;
  }
  .bike-info-2 p {
    font-size: 10px;
  }
  .bike-info-col {
    height: fit-content;
  }

  .contact-page-link {
    display: flex;
    justify-content: center;
  }
  .contact-page-link h3 {
    font-size: 18px;
  }
  .ab-btn-col {
    display: flex;
    justify-content: center;
  }
  .bike-info-tages span {
    font-size: 5px;
  }
  .bike-info-tages p {
    font-size: 5px;
  }

  .triangle-left {
    width: 0;
    height: 0;
    border-top: 10px solid transparent !important ;
    border-right: 16px solid !important;
    border-bottom: 40px solid transparent !important;
    opacity: 0.9;
  }
  .triangle-right {
    width: 0;
    height: 0;
    border-top: 40px !important ;
    border-left: 16px !important;
    border-bottom: 40px !important;
    opacity: 0.9;
  }

  .bike-details-col {
    padding: 10px;
  }

  /* Auto-rent-advantage-section endting */

  /* Home-gallary starting */
  .hr-gallary {
    width: 15%;
    color: #cd2034;
    border: 0;
    border-top: 2px solid;
    opacity: 0.9 !important;
    position: absolute;
    left: 35%;
  }
  .g-heading {
    font-weight: 900;
    position: relative;
  }
  .c-profile-1 {
    text-align: center;
    margin-left: 25%;
  }

  h4 {
    font-size: 25px !important;
  }
  .home-gallary {
    margin-top: 7%;
  }
  /* Home-gallary ending */

  /* footer-image page-startin */
  .footer {
    height: 100%;
  }

  .footer-image {
    background-color: #cd2034;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-image img {
    margin: 0% !important;
  }
  .footer-img-col .col-lg-12 {
    display: flex;
    justify-content: start;
    padding: 0px !important;
    height: 100px;
  }
  .footer-img-col {
    display: flex;
    justify-content: start;
  }
  .b-btn-1 {
    background-color: white !important;
    border: 1px solid white;
    border-radius: 20px;
    margin-right: 30%;
    padding: 6px !important;
    width: 150px;
    text-align: center;
    margin: 0px !important;
    width: 100%;
    color: black !important;
    font-size: 12px;
  }
  .quick-links p {
    color: white;
    margin-bottom: 0px !important;
    font-size: 14px;
  }
  .quick-links h6 {
    color: white;
  }

  .footer-div-1 {
    color: white;
    position: relative;
    left: 0%;
    top: 10% !important;
  }
  .quick-links {
    display: grid;
    justify-content: start;
  }
  .q-links {
    margin-top: 7% !important;
    margin-bottom: 5%;
  }
  .footer-bottom {
    padding-bottom: 40px;
  }

  /* footer-page ending */

  /* Crousel-page starting */

  .crousel-left-icon {
    position: relative;
    left: 4%;
    font-size: 17px;
    height: 15px !important;
    width: 15px !important;
  }

  .crousel-left-icon-p {
    border: 1px solid white;
    width: -moz-fit-content;
    width: fit-content;
    padding: 2px;
    border-radius: 20px;
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: -4%;
  }
  .carousel-caption {
    position: absolute;
    bottom: 1.25rem;
    left: 2% !important;
    top: 30%;

    color: #fff;
    text-align: start !important;
  }

  .carousel-control-prev-icon {
    display: inline-block;
    width: 1rem !important;
    height: 1rem !important;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: inline-block;
    width: 1rem !important;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
  }

  .carousel-caption h3 {
    font-size: 8px;
  }
  .carousel-caption p {
    font-size: 6px;
  }

  .carousel-control-next-icon {
    width: 10px !important;
  }
  .carousel-control-prev-icon {
    width: 10px !important;
  }
  p {
    margin-bottom: 0px !important;
  }
  .crousel-left-icon-p {
    margin-top: 2%;
  }
  .carousel-caption h3 {
    margin-bottom: 0px;
    margin-top: 0px !important;
  }

  /* Crousel-page end` */

  /* About-page starting */

  .row-about {
    display: flex;
    justify-content: center;
  }
  .row-about .col-lg-3 {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  .row-about .col-lg-3 .card {
    min-height: 300px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #91b2c1;
    color: white;
    width: 300px !important;
  }
  .row-about .col-lg-3 .card span svg {
    font-size: 50px;
  }
  .value-card p {
    margin: 0px !important;
  }
  .about-heading {
    text-align: center;
    color: rgb(205, 32, 52);
  }
  .About-Autorent {
    text-align: center;
    color: #cd2034;
  }
  .about-row-2 {
    display: flex;
    justify-content: center;
  }
  .ab-btn {
    --bs-btn-bg: none !important;
    border: 1px solid white !important;
  }

  /* About-page ending */

  .row-info-details .col-lg-5 img {
    width: 100%;
    height: auto;
  }
  .contact-us {
    display: flex;
    justify-content: center;
  }
  .card-2 {
    margin-top: 12%;
  }
  .footer .row {
    margin-top: 0% !important;
  }
  .about-pulsar-img {
    display: none;
  }
}

/* Media querrys 320 to 480 ending */

/* Media Querry 481 to 767 starting */

@media screen and (min-device-width: 481px) and (max-device-width: 767px) {
  .gallray-long-img {
    width: 100%;
  }
  .bike-info-tages {
    font-size: 10px;
    padding: 5px;
  }

  .triangle-left-5 {
    width: auto !important;
    height: auto !important;
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%) !important;
  }

  .triangle-right-5 {
    width: auto !important;
    height: auto !important; /* Adjust this value to change the height of the shape */
    clip-path: polygon(100% 0%, 0% 50%, 100% 100%) !important;
  }

  .gallray-long-img {
    width: 100%;
  }

  /* bike info start */
  .b-btn {
    border: 2px solid white;
    border-radius: 20px;
    margin-right: 30%;
    padding: 6px;
    width: 30% !important;
    text-align: center;
    margin: 0px !important;
    font-weight: 500;
  }
  .b-btn p {
    margin-left: 0%;
    font-size: 10px !important;
  }
  .bike-info {
    background: #d4915a;
    color: #fff;
    height: 300px;
    opacity: 0.9;
    padding-left: 5%;
    padding-right: 8%;
    padding-top: 5%;
    position: relative;
    left: 0%;
    width: 100%;
  }
  .bike-info h1 {
    margin: 0% !important;
  }
  .bike-info-2 h1 {
    margin: 0% !important;
  }
  .bike-info-2 {
    background: #f85e76;
    color: #fff;
    height: 350px;
    opacity: 0.9;
    padding-left: 5%;
    padding-right: 8%;
    padding-top: 5%;
    position: relative;
    right: 0%;
  }

  .bike-img img {
    width: 100%;
  }
  /* Bike-info end */

  /* navbar- start */
  .navbar {
    padding: 0px !important;
  }
  .me-auto-2 {
    display: none !important;
    width: 100%;
  }
  .me-auto-3 {
    display: block !important;
    width: 100%;
    background-color: #cd2034;
  }
  .me-auto-3 .col-lg-7 span a {
    color: black;
  }
  .book-now-btn-navbar {
    position: relative;
  }
  .icon-xs-col .nav-link {
    padding: 10px 10px 10px 10px;
    color: white !important;
  }
  .navbar-brand img {
    position: relative;
    left: 5% !important;
  }
  .navbar-toggler {
    position: relative;
    right: 5%;
  }

  /* navbar end */

  /* Owel-crousel starting */

  .item img {
    width: 170px !important;
  }

  .owl-theme-1 {
    border: none !important;
  }

  .owl-theme-p {
    margin-bottom: 0px;
    font-weight: 700;
    color: red !important;
    text-align: center;
  }
  .owl-theme-p-1 {
    margin-bottom: 0px;
    font-weight: 700;
    color: red !important;
    text-align: center;
  }
  .item {
    border-right: none;
    display: grid;
    justify-content: center;
  }
  /* Owel-crousel ending */

  /* Auto-rent-advantage-section starting */

  .circle-icon {
    background: #cd2034;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    padding: 5px;
    font-size: 20px;
  }

  .auto-rent-heading-1 {
    text-align: center;
    font-weight: 900;
    margin-right: 10%;
    font-size: 25px;
    margin-bottom: -6px;
  }
  .auto-rent-heading-2 {
    text-align: center;
    font-weight: 300;
    margin-left: 0%;
    font-size: 25px;
  }
  .auto-rent-heading-3 {
    text-align: center;
    background-color: #cd2034;
    padding: 5px 5px;
    width: 70% !important;
    color: white;
    font-size: 12px;
  }
  .auto-rent-font {
    text-align: center;
  }
  .heading-div {
    display: flex;
    justify-content: center;
  }
  .compant-profile div hr {
    width: 20%;
    color: #cd2034;
    border: 0;
    border-top: 2px solid;
    opacity: 0.9 !important;
    position: absolute;
    left: 15%;
  }
  .auto-rent-row {
    display: flex;
    justify-content: center;
  }

  .c-profile {
    font-weight: 700;
    margin-left: 38%;
  }
  .compant-profile {
    display: flex;
    justify-content: center;
  }
  .p-text {
    text-align: justify;
    margin-bottom: 5%;
    margin-top: 5%;
    width: 100%;
    padding: 0px 30px 0px 30px;
    font-size: 14px;
  }
  .arrow-p {
    font-size: 14px;
    padding: 0px 30px;
  }
  .icon-content h5 {
    font-size: 16px;
  }
  .icon-content p {
    font-size: 14px;
  }
  .icon-content {
    padding: 0px 0px !important;
  }
  .bike-info h1 {
    font-size: 16px;
  }
  .bike-info p {
    font-size: 14px;
  }

  .bike-info-2 h1 {
    font-size: 16px;
  }
  .bike-info-2 p {
    font-size: 14px;
  }

  .contact-page-link {
    display: flex;
    justify-content: center;
  }
  .contact-page-link h3 {
    font-size: 18px;
  }
  .ab-btn-col {
    display: flex;
    justify-content: center;
  }
  /* Auto-rent-advantage-section endting */

  /* Home-gallary starting */
  .hr-gallary {
    width: 15%;
    color: #cd2034;
    border: 0;
    border-top: 2px solid;
    opacity: 0.9 !important;
    position: absolute;
    left: 35%;
  }
  .g-heading {
    font-weight: 900;
    position: relative;
  }
  .c-profile-1 {
    text-align: center;
    margin-left: 25%;
  }

  h4 {
    font-size: 25px !important;
  }

  /* Home-gallary ending */

  /* footer-image page-startin */
  .footer {
    height: 100%;
  }

  .footer-image {
    background-color: #cd2034;
    width: 300px;
  }
  .footer-img-col .col-lg-12 {
    display: flex;
    justify-content: start;
    padding: 0px !important;
    height: 100px;
  }
  .footer-img-col {
    display: flex;
    justify-content: start;
  }
  .b-btn-1 {
    background-color: white !important;
    border: 1px solid white;
    border-radius: 20px;
    margin-right: 30%;
    padding: 6px !important;
    width: 150px;
    text-align: center;
    margin: 0px !important;
    width: 100%;
    color: black !important;
    font-size: 12px;
  }
  .quick-links p {
    color: white;
    margin-bottom: 0px !important;
    font-size: 14px;
  }
  .quick-links h6 {
    color: white;
  }

  .footer-div-1 {
    color: white;
    position: relative;
    left: 0%;
    top: 10% !important;
  }
  .quick-links {
    display: grid;
    justify-content: start;
    position: relative;
    left: 0%;
    top: 20%;
  }
  .footer-bottom {
    padding-bottom: 40px;
  }

  /* footer-page ending */

  /* Crousel-page starting */

  .crousel-left-icon {
    position: relative;
    left: 4%;
    font-size: 17px;
    height: 15px !important;
    width: 15px !important;
  }

  .crousel-left-icon-p {
    border: 1px solid white;
    width: -moz-fit-content;
    width: fit-content;
    padding: 2px;
    border-radius: 20px;
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: -4%;
  }
  .carousel-caption {
    position: absolute;
    bottom: 1.25rem;
    left: 2% !important;
    top: 30%;

    color: #fff;
    text-align: start !important;
  }

  .carousel-control-prev-icon {
    display: inline-block;
    width: 1rem !important;
    height: 1rem !important;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: inline-block;
    width: 1rem !important;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
  }

  .carousel-caption h3 {
    font-size: 8px;
  }
  .carousel-caption p {
    font-size: 6px;
  }

  .carousel-control-next-icon {
    width: 10px !important;
  }
  .carousel-control-prev-icon {
    width: 10px !important;
  }
  p {
    margin-bottom: 0px !important;
  }
  .crousel-left-icon-p {
    margin-top: 2%;
  }
  .carousel-caption h3 {
    margin-bottom: 0px;
    margin-top: 0px !important;
  }
  .crousel-left-icon {
    position: relative;
    left: 4%;
    font-size: 17px;
    height: 15px !important;
    width: 15px !important;
  }

  /* Crousel-page end` */

  /* About-page starting */

  .row-about {
    display: flex;
    justify-content: center;
  }
  .row-about .col-lg-3 {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  .row-about .col-lg-3 .card {
    min-height: 300px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #91b2c1;
    color: white;
    width: 300px !important;
  }
  .row-about .col-lg-3 .card span svg {
    font-size: 50px;
  }
  .value-card p {
    margin: 0px !important;
  }
  .about-heading {
    text-align: center;
    color: rgb(205, 32, 52);
  }
  .About-Autorent {
    text-align: center;
    color: #cd2034;
  }
  .about-row-2 {
    display: flex;
    justify-content: center;
  }
  .ab-btn {
    --bs-btn-bg: none !important;
    border: 1px solid white !important;
  }

  /* About-page ending */

  .row-info-details .col-lg-5 img {
    width: 100%;
    height: auto;
  }
  .contact-us {
    display: flex;
    justify-content: center;
  }
  .card-2 {
    margin-top: 12%;
  }
}
/* Media Querry 481 and 767 ending */

/* Media Querry 768 and 1024 starting */

@media screen and (min-device-width: 768px) and (max-device-width: 1200px) {
  .gallray-long-img {
    width: 100%;
  }

  .gallray-long-img {
    width: 100%;
  }

  /* bike info start */
  .b-btn {
    border: 2px solid white;
    border-radius: 20px;
    margin-right: 30%;
    padding: 6px;
    width: 35% !important;
    text-align: center;
    margin: 0px !important;
    font-weight: 500;
  }
  .b-btn p {
    margin-left: 0%;
    font-size: 10px !important;
  }
  .bike-info {
    background: #d4915a;
    color: #fff;
    height: 300px;
    opacity: 0.9;
    padding-left: 5%;
    padding-right: 8%;
    padding-top: 5%;
    position: relative;
    left: 0%;
    width: 100%;
  }
  .bike-info h1 {
    margin: 0% !important;
  }
  .bike-info-2 h1 {
    margin: 0% !important;
  }
  .bike-info-2 {
    background: #f85e76;
    color: #fff;
    height: 350px;
    opacity: 0.9;
    padding-left: 5%;
    padding-right: 8%;
    padding-top: 5%;
    position: relative;
    right: 0%;
  }

  .bike-img img {
    width: 100%;
  }
  /* Bike-info end */

  /* navbar- start */
  .navbar {
    padding: 0px !important;
  }
  .me-auto-2 {
    display: none !important;
    width: 100%;
  }
  .me-auto-3 {
    display: block !important;
    width: 100%;
    background-color: #cd2034;
  }
  .me-auto-3 .col-lg-7 span a {
    color: black;
  }
  .book-now-btn-navbar {
    position: relative;
  }
  .icon-xs-col .nav-link {
    padding: 10px 10px 10px 10px;
    color: white !important;
  }
  .navbar-brand img {
    position: relative;
    left: 5% !important;
  }
  .navbar-toggler {
    position: relative;
    right: 5%;
  }

  /* navbar end */

  .carousel-caption {
    display: none;
  }

  /* Owel-crousel starting */

  .item img {
    width: 170px !important;
  }

  .owl-theme-1 {
    border: none !important;
  }

  .owl-theme-p {
    margin-bottom: 0px;
    font-weight: 700;
    color: red !important;
    text-align: center;
  }
  .owl-theme-p-1 {
    margin-bottom: 0px;
    font-weight: 700;
    color: red !important;
    text-align: center;
  }
  .item {
    border-right: none;
    display: grid;
    justify-content: center;
  }
  /* Owel-crousel ending */

  /* Auto-rent-advantage-section starting */

  .circle-icon {
    background: #cd2034;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    padding: 5px;
    font-size: 20px;
  }

  .auto-rent-heading-1 {
    text-align: center;
    font-weight: 900;
    margin-right: 10%;
    font-size: 25px;
    margin-bottom: -6px;
  }
  .auto-rent-heading-2 {
    text-align: center;
    font-weight: 300;
    margin-left: 0%;
    font-size: 25px;
  }
  .auto-rent-heading-3 {
    text-align: center;
    background-color: #cd2034;
    padding: 5px 5px;
    width: 40% !important;
    color: white;
    font-size: 12px;
  }
  .auto-rent-font {
    text-align: start;
    font-size: 18px;
  }
  .side-img {
    width: 100%;
    height: auto;
  }
  .heading-div {
    display: flex;
    justify-content: center;
  }
  .compant-profile div hr {
    width: 10%;
    color: #cd2034;
    border: 0;
    border-top: 2px solid;
    opacity: 0.9 !important;
    position: absolute;
    left: 9%;
  }
  .auto-rent-row {
    display: flex;
    justify-content: center;
  }

  .c-profile {
    font-weight: 700;
    margin-left: 38%;
  }
  .compant-profile {
    display: flex;
    justify-content: center;
  }
  .p-text {
    text-align: justify;
    margin-bottom: 5%;
    margin-top: 5%;
    width: 100%;
    padding: 0px 30px 0px 0px;
    font-size: 14px;
  }
  .arrow-p {
    font-size: 14px;
    padding: 0px 0px;
  }
  .icon-content h5 {
    font-size: 16px;
  }
  .icon-content p {
    font-size: 14px;
  }
  .icon-content {
    padding: 0px 0px !important;
  }
  .bike-info h1 {
    font-size: 16px;
  }
  .bike-info p {
    font-size: 14px;
  }

  .bike-info-2 h1 {
    font-size: 16px;
  }
  .bike-info-2 p {
    font-size: 14px;
  }

  .contact-page-link {
    display: flex;
    justify-content: center;
  }
  .contact-page-link h3 {
    font-size: 18px;
  }
  .ab-btn-col {
    display: flex;
    justify-content: center;
  }
  /* Auto-rent-advantage-section endting */

  /* Home-gallary starting */
  .hr-gallary {
    width: 10%;
    color: #cd2034;
    border: 0;
    border-top: 2px solid;
    opacity: 0.9 !important;
    position: absolute;
    left: 41%;
  }
  .g-heading {
    font-weight: 900;
    position: relative;
  }
  .c-profile-1 {
    text-align: center;
    margin-left: 14%;
  }

  h4 {
    font-size: 25px !important;
  }

  .triangle-right {
    width: 0;
    height: 0;
    border-top: 80px solid transparent;
    border-left: 77px solid #d4915a;
    border-bottom: 80px solid transparent;
    opacity: 0.9;
  }

  .triangle-right-1 {
    width: 0;
    height: 0;
    border-top: 80px solid transparent;
    border-left: 77px solid #4588e3;
    border-bottom: 80px solid transparent;
    opacity: 0.9;
  }
  .triangle-left {
    width: 0;
    height: 0;
    border-top: 80px solid transparent;
    border-right: 77px solid #e54056;
    border-bottom: 80px solid transparent;
    opacity: 0.9 !important;
  }

  .triangle-left-1 {
    width: 0;
    height: 0;
    border-top: 80px solid transparent;
    border-right: 77px solid #dbddde;
    border-bottom: 80px solid transparent;
    opacity: 0.9 !important;
  }

  /* Home-gallary ending */

  /* footer-image page-startin */
  .footer {
    height: 100%;
    width: 100%;
  }

  .footer-image {
    background-color: #cd2034;
    width: 150px;
  }
  .footer-image img {
    margin-top: 5% !important;
  }

  .footer-btn {
    border: none !important;
    border-radius: 999em 20px 20px 999em;
    padding: 12px;
    width: 120px;
    font-size: 10px;
    background-color: white;
    color: black;
    box-shadow: none !important;
  }

  .footer-phone-btn {
    border-radius: 20px 999em 999em 20px;
    padding: 13px;
    background-color: rgb(191, 51, 67);
    margin-top: 5px !important;
    width: 50px !important;
    font-size: 10px !important;
    position: relative;
    right: 2%;
  }
  .footer-div-1 {
    color: white;
    position: relative;
    left: 5%;
  }
  /* Footer-ending */

  /* Crousel-page starting */

  .crousel-left-icon-p {
    border: 1px solid white;
    width: -moz-fit-content;
    width: fit-content;
    padding: 2px;
    border-radius: 20px;
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: -4%;
  }
  .carousel-caption {
    position: absolute;
    bottom: 1.25rem;
    left: 2% !important;
    top: 50%;

    color: #fff;
    text-align: start !important;
  }

  .carousel-control-prev-icon {
    display: inline-block;
    width: 1rem !important;
    height: 1rem !important;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: inline-block;
    width: 1rem !important;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
  }

  .carousel-caption h3 {
    font-size: 20px;
  }
  .carousel-caption p {
    font-size: 16px;
    margin: 2% 0% 2% 0%;
  }

  .carousel-control-next-icon {
    width: 10px !important;
  }
  .carousel-control-prev-icon {
    width: 10px !important;
  }
  p {
    margin-bottom: 0px !important;
  }
  .crousel-left-icon-p {
    margin-top: 2%;
  }
  .carousel-caption h3 {
    margin-bottom: 0px;
    margin-top: 0px !important;
  }
  .crousel-left-icon {
    position: relative;
    left: 4%;
    font-size: 17px;
    height: 18px !important;
    width: 18px !important;
  }

  p span {
    padding: 0px 8px 0px 8px;
    font-size: 10px;
  }

  /* Crousel-page end` */

  /* About-page starting */

  .row-about {
    display: flex;
    justify-content: center;
  }
  .row-about .col-lg-3 {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  .row-about .col-lg-3 .card {
    min-height: 300px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #91b2c1;
    color: white;
    width: 300px !important;
  }
  .row-about .col-lg-3 .card span svg {
    font-size: 50px;
  }
  .value-card p {
    margin: 0px !important;
  }
  .about-heading {
    text-align: center;
    color: rgb(205, 32, 52);
  }
  .About-Autorent {
    text-align: center;
    color: #cd2034;
  }
  .about-row-2 {
    display: flex;
    justify-content: center;
  }
  .ab-btn {
    --bs-btn-bg: none !important;
    border: 1px solid white !important;
  }

  /* About-page ending */

  .row-info-details .col-lg-5 img {
    width: 100%;
    height: auto;
  }
  .contact-us {
    display: flex;
    justify-content: center;
  }
  .card-2 {
    margin-top: 12%;
  }
  .carousel-caption {
    display: block;
  }

  .card-position {
    position: relative;
    right: 5%;
    top: 15%;
  }
}

/* BOOKING-NOW STARTING */
.owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  top: 40%;
  left: -15px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #000;
  font-size: 50px !important;
}

.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 40%;
  right: -15px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #000;
  font-size: 50px !important;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #869791 !important;
  color: #fff;
  text-decoration: none;
}
.booking-now {
  background-color: #cd2034 !important;
  width: 30%;
  display: flex;
  justify-content: center;
  color: white !important;
}
.theme-dot h6 {
  font-size: 10px;
}

/* BOOKING-NOW ENDING */

/* Media Querry 768 and 1024 ending */

/* .active {
  border-color:  #CD2034 !important;
  border-width: 1.5px !important;
} */

.react-datepicker {
  position: absolute !important;
  top: -3100% !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background: none !important;
  color: black !important;
  text-decoration: none !important;
}

.tag {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  position: relative;
  width: 80%;
  padding: 20px;
}

.tag::before {
  content: "";
  position: absolute;
  top: 0;
  right: -20%;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 91px 43px 91px 50px;
  border-color: transparent transparent transparent #007bff;
}

.tag-text {
  position: relative;
  z-index: 1;
}

.pencil-shape {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 40px solid #f2b63c; /* Color of the pencil body */
  position: relative;
}

.pencil-shape::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 40px;
  width: 10px;
  height: 40px;
  background-color: #f2b63c; /* Color of the pencil tip */
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}

.triangle-right-5 {
  width: 160px;
  height: 160px; /* Adjust this value to change the height of the shape */
  clip-path: polygon(100% 0%, 0% 50%, 100% 100%) !important;
}
.triangle-left-5 {
  width: 120px;
  height: 160px; /* Adjust this value to change the height of the shape */
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%) !important;
}

.img-wrapper {
  position: relative;
  width: 100%;
  padding-top: 50%; /* This creates a 2:1 aspect ratio (height is 50% of width) */
}

.img-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the area while maintaining aspect ratio */
}

.autorent-about-row .col-lg-6 h1 {
  font-size: 28px;
  font-weight: 700;
}

img {
  object-fit: cover;
}

.carrer-job-desc {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 40px !important;
}
.carrer-job-form {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 40px !important;
}

.partnerCarousel {
  max-height: 40px;
}

.partners {
  margin-bottom: 100px;
}
